<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">
              {{cvCardSubHeader}}
            </p>
            <form action="#" v-if="vmCareerOpportunityFormData && Object.keys(vmCareerOpportunityFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_field">
                    {{cvCarrFieldLabel}}</label>
                    <ValidationProvider
                      name="Career Field"
                      rules="required"
                      v-slot="{ errors }">
                        <input
                          v-model="vmCareerOpportunityFormData.carr_field"
                          type="text"
                          class="form-control"
                          required
                          disabled/>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_opp_title">
                    {{cvCarrOppTitleLabel}}</label>
                  <ValidationProvider
                    name="Career Opportunity"
                    rules="required"
                    v-slot="{ errors }">
                  <input
                    v-model="vmCareerOpportunityFormData.carr_opp_title"
                    type="text"
                    class="form-control"
                    required/>
                  <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_opp_desc">
                    {{cvCarrOppDescLabel}}</label>
                    <ValidationProvider
                      name="Career Opportunity Description"
                      rules="required"
                      v-slot="{ errors }">
                    <vue-editor v-model="vmCareerOpportunityFormData.carr_opp_desc"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
              </div>
              <div class="form-group row spinner_add ml-1">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="careerOpportunityEdit()">
                  {{cvSubmitBtn}}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { CareerOpportunitys } from "../../../FackApi/api/careerOpportunity"
import ApiResponse from "../../../Utils/apiResponse.js"
import { VueEditor } from "vue2-editor"

export default {
  name: "CareerOpportunityEdit",
  components: {
    VueEditor
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCareerOpportunityObj: {
      type: Object,
      default: function () {
        return {
          "carr_field": "",
          "carr_opp_title": "",
          "carr_opp_desc": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit careerOpportunity",
      cvCardSubHeader: "Edit careerOpportunity ",
      cvSubmitBtn: "Edit",
      cvCarrFieldLabel: "career",
      cvCarrOppTitleLabel: "carr Opportunity title",
      cvCarrOppDescLabel: "carr Opportunity desc",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "CareerOpportunity",
      vmCareerOpportunityFormData: {}
    }
  },
  mounted () {
    this.careerOpportunityView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerOpportunityFormData) {
          if (!this.vmCareerOpportunityFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * careerOpportunityView
     */
    async careerOpportunityView () {
      try {
        if (this.propOpenedInModal) {
          this.vmCareerOpportunityFormData = this.propCareerOpportunityObj
        }
        else {
          let careerOpportunityId = this.$route.params.carr_opp_id
          let careerOpportunityViewResp = await CareerOpportunitys.careerOpportunityView(this, careerOpportunityId)
          if (careerOpportunityViewResp && careerOpportunityViewResp.resp_status) {
            this.vmCareerOpportunityFormData = careerOpportunityViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at careerOpportunityView() and Exception:", err.message)
      }
    },
    /**
     * careerOpportunityEdit
     */
    async careerOpportunityEdit () {
      let valid = await this.$refs.carrOppForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        let careerOpportunityEditResp = await CareerOpportunitys.careerOpportunityEdit(this, this.vmCareerOpportunityFormData)
        await ApiResponse.responseMessageDisplay(this, careerOpportunityEditResp)
        if (careerOpportunityEditResp && !careerOpportunityEditResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerOpportunityEditModal", this.vmCareerOpportunityFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at careerOpportunityEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
