<template>
  <b-container fluid >
    <b-row>
      <b-col class="heading_alignment" md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="careerOpportunityAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template >
            <b-row class="mt-3">
               <b-col class="col-12 col-sm-8 col-md-6 col-lg-7 mb-3">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
                <b-col class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="careerOpportunityObjList && careerOpportunityObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="careerOpportunityObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(carr_field)="data">
                    <p v-html="data.item.carr_field"></p>
                    <b-button class="btn actionIcon mr-1 mb-1" size="sm" @click="careerOpportunityEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0 font-size-20" title="Edit"></i>
                    </b-button>
                    <b-button class=" btn actionIcon" @click="showCareerOpportunityDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0 font-size-20" title="Delete"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelCareerOpportunityAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <CareerOpportunityAdd :propOpenedInModal="true" @emitCloseCareerOpportunityAddModal="closeCareerOpportunityAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerOpportunityAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerOpportunityAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelCareerOpportunityEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <CareerOpportunityEdit :propOpenedInModal="true" :propCareerOpportunityObj="careerOpportunityEditObj" @emitCloseCareerOpportunityEditModal="closeCareerOpportunityEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerOpportunityEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerOpportunityEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelCareerOpportunityDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelCareerOpportunityDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="careerOpportunityDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<style lang="scss" scoped>
.iq-card{
  padding: 20px 0px;
}
.iq-card-header{
  padding-bottom:10px;
}
</style>

<script>
import { CareerOpportunitys } from "../../../FackApi/api/careerOpportunity"
import CareerOpportunityAdd from "./CareerOpportunityAdd"
import CareerOpportunityEdit from "./CareerOpportunityEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "CareerOpportunityList",
  components: {
    CareerOpportunityAdd,
    CareerOpportunityEdit,
    CsvUpload,
    CsvDownload
  },
  data () {
    return {
      cvCardTitle: "Career Opportunities",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit CareerOpportunity",
      cvAddModalHeader: "Add CareerOpportunity",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "CareerOpportunity List Response",
      showModelCareerOpportunityAdd: false,
      showModelCareerOpportunityEdit: false,
      showModelCareerOpportunityDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "career", key: "carr_field", class: "text-left align-text-top", sortable: true },
        { label: "carr opp title", key: "carr_opp_title", class: "text-left align-text-top", sortable: true },
        { label: "carr opp desc", key: "carr_opp_desc", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      careerOpportunityObjList: null,
      careerOpportunityEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      cvModulePrefix: "carr_opp",
      cvModuleFile: "career_opportunity"
    }
  },
  mounted () {
    this.careerOpportunityList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * careerOpportunityList
     */
    async careerOpportunityList () {
      try {
        let careerOpportunityListResp = await CareerOpportunitys.careerOpportunityList(this)
        if (careerOpportunityListResp.resp_status) {
          this.careerOpportunityObjList = careerOpportunityListResp.resp_data.data
          this.totalRows = careerOpportunityListResp.resp_data.count
        }
        else {
          this.toastMsg = careerOpportunityListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at careerOpportunityList() and Exception:", err.message)
      }
    },
    /**
     * careerOpportunityAdd
     */
    careerOpportunityAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/careerOpportunity_add")
        }
        else {
          this.showModelCareerOpportunityAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at careerOpportunityAdd() and Exception:", err.message)
      }
    },
    /**
     * careerOpportunityEdit
     */
    careerOpportunityEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/careerOpportunity_edit/" + this.careerOpportunityEditObj.carr_opp_id)
        }
        else {
          this.careerOpportunityEditObj = item
          this.showModelCareerOpportunityEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at careerOpportunityEdit() and Exception:", err.message)
      }
    },
    /**
     * showCareerOpportunityDeleteDialog
     */
    showCareerOpportunityDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelCareerOpportunityDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showCareerOpportunityDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * careerOpportunityDelete
     */
    async careerOpportunityDelete () {
      try {
        let careerOpportunityDelResp = await CareerOpportunitys.careerOpportunityDelete(this, this.delObj.carr_opp_id)
        await ApiResponse.responseMessageDisplay(this, careerOpportunityDelResp)
        if (careerOpportunityDelResp && !careerOpportunityDelResp) {
          this.showModelCareerOpportunityDelete = false
          return false
        }
        let index = this.careerOpportunityObjList.indexOf(this.delObj)
        this.careerOpportunityObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelCareerOpportunityDelete = false
      }
      catch (err) {
        console.error("Exception occurred at careerOpportunityDelete() and Exception:", err.message)
      }
    },
    /**
     * closeCareerOpportunityAddModal
     */
    closeCareerOpportunityAddModal (careerOpportunityAddData) {
      try {
        if (careerOpportunityAddData) {
          if (this.careerOpportunityObjList && this.careerOpportunityObjList.length >= 1) {
            let carroppObjLength = this.careerOpportunityObjList.length
            let lastId = this.careerOpportunityObjList[carroppObjLength - 1]["id"]
            careerOpportunityAddData.id = lastId + 1
          }
          else {
            this.careerOpportunityObjList = []
            careerOpportunityAddData.id = 11111
          }
          careerOpportunityAddData.created_on = moment()
          this.careerOpportunityObjList.unshift(careerOpportunityAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelCareerOpportunityAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closecareerOpportunityAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeCareerOpportunityEditModal
     */
    closeCareerOpportunityEditModal () {
      try {
        this.showModelCareerOpportunityEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeCareerOpportunityEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
